import React, { useState } from 'react'
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Collapse, Box } from '@mui/material'
import { Home, Send, ContactSupport, Category, QuestionAnswer, Business, BugReport, ExpandLess, ExpandMore, Fireplace } from '@mui/icons-material'
import Logo from '../../assets/logo-boussole.svg'
import * as ROUTES from '../../_constants/routes'
import { useLocation, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'


const itemStyle = active => ({
  color: active ? '#4fc3f7' : 'rgba(255, 255, 255, 0.7)',
  bgcolor: '#232f3e',
  boxShadow: '0 -1px 0 #404854 inset',
  py: 2,
  cursor: 'pointer',
  '&:hover,&:focus': {
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
  },
})

const NavigatorDrawer = ({ sx }) => {
  
  const navigate = useNavigate()
  const { pathname } = useLocation()
  
  const [openCollapseOneOnOne, setOpenCollapseOneOnOne] = useState(pathname.includes('one-on-one'))
  
  return (
    <Drawer variant='permanent' PaperProps={{
      sx: {
        bgcolor: '#232f3e',
        ...sx,
      },
    }}
    >
      <List disablePadding>
        <ListItem onClick={() => navigate(ROUTES.HOME)} sx={itemStyle(false)}>
          <Box component='img' src={Logo} alt='Boussole' sx={{ width: '40%' }} />
        </ListItem>
        <ListItem onClick={() => navigate(ROUTES.HOME)} sx={itemStyle(pathname === ROUTES.HOME)}>
          <ListItemIcon sx={{ minWidth: 'auto', mr: 2 }}><Home /></ListItemIcon>
          <ListItemText sx={{ fontSize: 'inherit' }}>Home</ListItemText>
        </ListItem>
        <ListItem onClick={() => navigate(ROUTES.DATA)} sx={itemStyle(pathname.includes(ROUTES.DATA))}>
          <ListItemIcon sx={{ minWidth: 'auto', mr: 2 }}><Business /></ListItemIcon>
          <ListItemText sx={{ fontSize: 'inherit' }}>Data</ListItemText>
        </ListItem>
        <ListItem onClick={() => navigate(ROUTES.DEPLOY)} sx={itemStyle(pathname === ROUTES.DEPLOY)}>
          <ListItemIcon sx={{ minWidth: 'auto', mr: 2 }}><Send /></ListItemIcon>
          <ListItemText sx={{ fontSize: 'inherit' }}>Commits</ListItemText>
        </ListItem>
        <ListItem onClick={() => navigate(ROUTES.DEBUG)} sx={itemStyle(pathname.includes(ROUTES.DEBUG))}>
          <ListItemIcon sx={{ minWidth: 'auto', mr: 2 }}><BugReport /></ListItemIcon>
          <ListItemText sx={{ fontSize: 'inherit' }}>Debugging</ListItemText>
        </ListItem>
        <ListItem onClick={() => setOpenCollapseOneOnOne(prev => !prev)} sx={{
          py: 1,
          color: pathname === ROUTES.DEBUG ? '#4fc3f7' : 'rgba(255, 255, 255, 0.7)',
          cursor: 'pointer',
          '&:hover,&:focus': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
          },
        }}>
          <ListItemIcon sx={{ minWidth: 'auto', mr: 2 }}><QuestionAnswer /></ListItemIcon>
          <ListItemText sx={{ fontSize: 'inherit' }}>One on One</ListItemText>
          {openCollapseOneOnOne ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openCollapseOneOnOne} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            <ListItem onClick={() => navigate(ROUTES.ONEONONETOPICS)} sx={itemStyle(pathname === ROUTES.ONEONONETOPICS)}>
              <ListItemIcon sx={{ minWidth: 'auto', mr: 2 }}><Category /></ListItemIcon>
              <ListItemText sx={{ fontSize: 'inherit' }}>Categories</ListItemText>
            </ListItem>
            <ListItem onClick={() => navigate(ROUTES.ONEONONEQUESTIONS)} sx={itemStyle(pathname === ROUTES.ONEONONEQUESTIONS)}>
              <ListItemIcon sx={{ minWidth: 'auto', mr: 2 }}><ContactSupport /></ListItemIcon>
              <ListItemText sx={{ fontSize: 'inherit' }}>Questions</ListItemText>
            </ListItem>
          </List>
        </Collapse>
        <ListItem onClick={() => navigate(ROUTES.CAMPFIRES)} sx={itemStyle(pathname.includes(ROUTES.CAMPFIRES))}>
          <ListItemIcon sx={{ minWidth: 'auto', mr: 2 }}><Fireplace /></ListItemIcon>
          <ListItemText sx={{ fontSize: 'inherit' }}>Campfires</ListItemText>
        </ListItem>
      </List>
    </Drawer>
  )
}

NavigatorDrawer.propTypes = {
  sx: PropTypes.object,
}

export default NavigatorDrawer
